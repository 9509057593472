import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/interest-rate-impact-san-diego-real-estate";

  return (
    <LayoutWrapper
      title="The Impact Of Interest Rates On San Diego's Real Estate Market & How To Navigate | The Selby Team"
      desc="Learn how interest rates impact the San Diego real estate market and how to navigate the market in any climate."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              The Impact Of Interest Rates On San Diego's Real Estate Market & How To Navigate
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By Sarah Harris
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Oct 26, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_The Impact Of Interest Rates On San Diego's Real Estate Market & How To Navigate.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Like elsewhere throughout the country, interest rates in San Diego have risen
                dramatically within the past few years. It is a trend that impacts individual{" "}
                <a
                  href="https://selbysellssd.com/san-diego-real-estate-agents/"
                  target="_Blank"
                  className="text-blue-500"
                >
                  buyers
                </a>{" "}
                and{" "}
                <a
                  href="https://selbysellssd.com/sell-your-san-diego-home/"
                  target="_Blank"
                  className="text-blue-500"
                >
                  sellers
                </a>{" "}
                while also having more of an effect on the overall real estate market than many
                people realize.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                That said, here’s a breakdown of how to navigate San Diego’s real estate market in
                our current high-interest world.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Interest Rates in San Diego: Then and Now
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As of October 2023, San Diego’s mortgage interest rates{" "}
                <a
                  href="https://www.nerdwallet.com/mortgages/mortgage-rates/california/san-diego#:~:text=Today's%20rate&text=Today's%20mortgage%20rates%20in%20San,on%20mortgages%20and%20real%20estate"
                  target="_Blank"
                  className="text-blue-500"
                >
                  are as follows
                </a>{" "}
                <ul className="list-disc px-[20px] text-xs">
                  <li>30-Year Fixed: 7.580%</li>
                  <li>20-Year Fixed: 7.546%</li>
                  <li>15-Year Fixed: 6.496% </li>
                </ul>
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For the sake of comparison, the average mortgage rates for the state of California
                in{" "}
                <a
                  href="https://www.foxla.com/money/todays-california-mortgage-rates-december-22-2020"
                  target="_Blank"
                  className="text-blue-500"
                >
                  December 2020
                </a>{" "}
                were as follows:
                <ul className="list-disc px-[20px] text-xs">
                  <li>30-Year Fixed: 2.500%</li>
                  <li>20-Year Fixed: 2.375%</li>
                  <li>15-Year Fixed: 1.875%</li>
                </ul>
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As you can see, mortgage rates have risen in a short time, and given that homes in
                San Diego tend to be costly (the median price is{" "}
                <a
                  href="https://www.redfin.com/city/16904/CA/San-Diego/housing-market"
                  target="_Blank"
                  className="text-blue-500"
                >
                  $930,000
                </a>{" "}
                ), even a 10% down payment isn’t feasible for many. Combining a high-interest rate,
                the monthly mortgage payment and the total home purchase cost can price some
                homeowners out of the market altogether.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For example, if you were looking to purchase a $930,000 home with a 20% down payment
                and a 30-year fixed mortgage in December 2020 (at a rate of 2.500%), your total cost
                (excluding taxes, fees, and other expenditures) would be approximately $1,058,292.
                If you were making that same purchase with today’s rate of 7.580%, you would pay
                just about $1,887,470.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                How to Navigate San Diego's Real Estate Market
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Interest rates in San Diego make house hunting substantially difficult, but it
                doesn’t have to be impossible. Here are a few tips to keep your costs to a minimum:
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Negotiate With Sellers
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Between rising home prices and rising interest rates, some sellers are having
                trouble finding buyers. As a result, some are more willing to make a few concessions
                to make a sale.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Improve Your Credit Score
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Lowering your interest rates even very slightly in such a high-priced market can
                make a major difference. Improving your credit score is one of the best ways to
                secure a lower rate. Do your best to lower your debt-to-income ratio and resolve any
                errors on your report.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Think About Adjustable-Rate Mortgages
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                An adjustable-rate mortgage leaves the possibility that your rate may lower over
                time. However, these mortgages aren’t without risk, so it’s essential to evaluate
                your situation holistically before making a decision.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Don’t Be Afraid to Negotiate With Lenders
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                It might seem like the mortgage a lender offers you is your only option, but if you
                have a strong overall credit profile, trying to negotiate a better rate might be
                worthwhile.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Thinking About Buying a Home in San Diego?
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                High interest rates can be discouraging, but when you work with{" "}
                <a
                  href="https://selbysellssd.com/san-diego-real-estate-agents/"
                  target="_Blank"
                  className="text-blue-500"
                >
                  the right real estate experts
                </a>{" "}
                , it’s still possible to find your dream home for a fair price.
                <ContactSlideoutLink text="Contact The Selby Team" /> to get the ball rolling toward
                finding your new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
